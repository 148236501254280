import moment from "moment";
import { IconButton, makeStyles, TablePagination } from "@material-ui/core";
import Table from "../../theme/components/Table/Table";
import { Visibility } from "@material-ui/icons";
import CopyWithAlert from "../../utils/CopyWithAlert";
import getStatusColor from "../../utils/functions/getStatusColor";

const BankCardsTable = ({
	data,
	params,
	total,
	columns,
	isTabsAll,
	onPageChange,
	onRowsPerPageChange,
	onManage,
}) => {
	const classes = useStyles();
	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, headerName, colSpan, align }) => (
							<th key={key} colSpan={colSpan} align={align || "left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.length > 0 ? (
						data?.map((item, index) => (
							<tr key={index}>
								<CopyWithAlert text={item?.userId + "" || ""} label={"userId"}>
									<td data='hover'>{item?.userId}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.cardName + "" || ""} label={"Card Name"}>
									<td data='hover'>{item?.cardName}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.cardNumber + "" || ""} label={"Card Number"}>
									<td data='hover'>{item?.cardNumber}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.iban + "" || ""} label={"IBAN"}>
									<td className={classes.tdShort} data='hover'>{item?.iban}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.itn + "" || ""} label={"ITN"}>
									<td className={classes.tdShort} data='hover'>{item?.itn}</td>
								</CopyWithAlert>

								<CopyWithAlert text={item?.cardIssuer + "" || ""} label={"Card Issuer"}>
									<td data='hover'>{item?.cardIssuer}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.ownerFirstName + "" || ""}
									label={"First Name"}>
									<td data='hover'>{item?.ownerFirstName}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.ownerLastName + "" || ""}
									label={"Last Name"}>
									<td data='hover'>{item?.ownerLastName}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.ownerMiddleName + "" || ""}
									label={"Middle Name"}>
									<td data='hover'>{item?.ownerMiddleName}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.phoneNumber + "" || ""} label={"Phone Number"}>
									<td data='hover'>{item?.phoneNumber}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.status || ""} label={"Status"}>
									<td
										data='hover'
										style={{
											color: getStatusColor(item?.status),
										}}>
										{item?.status || "-"}
									</td>
								</CopyWithAlert>
								<td>
									{item?.creationDate
										? moment(item?.creationDate).format("DD MMM YYYY HH:mm")
										: "-"}
								</td>
								<td>
									{item?.expirationDate
										? moment(item?.expirationDate).format("DD MMM YYYY HH:mm")
										: "-"}
								</td>
								{isTabsAll && (
									<>
										<td>
											{item?.completeDate
												? moment(item?.completeDate).format("DD MMM YYYY HH:mm")
												: "-"}
										</td>
									</>
								)}
								<td>
									<IconButton onClick={() => onManage(item?.id)}>
										<Visibility />
									</IconButton>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={12} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total || 0}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default BankCardsTable;

const useStyles = makeStyles((theme) => ({
	tdShort: {
		maxWidth: '80px !important'
	},
}));

