import axios from "axios";
import * as R from "ramda";
import { BASE_URL, AUTH_TOKEN } from "../utils/constants/api";
import { store } from "./store";
import { logOut } from "./actions/accountActions";
import { Buffer } from "buffer";

class Api {
	constructor() {
		this.client = axios.create({
			baseURL: BASE_URL,
			withCredentials: true,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});

		// Handle request process
		this.client.interceptors.request.use(
			async (config) => {
				if (localStorage.getItem("access_token")) {
					return R.assocPath(
						["headers", "common", "Authorization"],
						`Bearer ${localStorage.getItem("access_token")}`,
						config
					);
				} else {
					return config;
				}
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		// Handle response process
		let isRefreshing = false;
		let failedQueue = [];

		const processQueue = (error, token = null) => {
			failedQueue.forEach((prom) => {
				if (error) {
					prom.reject(error);
				} else {
					prom.resolve(token);
				}
			});

			failedQueue = [];
		};

		this.client.interceptors.response.use(
			(response) => {
				return response;
			},
			async function (error) {
				if (
					error?.response?.status === 401 &&
					error?.response?.data?.error_description?.includes(
						"Invalid refresh token"
					)
				) {
					store.dispatch(logOut());
				}
				const originalRequest = error.config;
				if (error?.response?.status === 401 && !originalRequest._retry) {
					originalRequest._retry = true;
					if (isRefreshing) {
						return new Promise(function (resolve, reject) {
							failedQueue.push({ resolve, reject });
						})
							.then((token) => {
								originalRequest.headers["Authorization"] = "Bearer " + token;
								return axios(originalRequest);
							})
							.catch((err) => {
								return Promise.reject(err);
							});
					}

					originalRequest._retry = true;
					isRefreshing = true;
					const refreshToken = localStorage.getItem("refresh_token");
					return new Promise(function (resolve, reject) {
						let formData = new FormData();
						formData.append("refresh_token", refreshToken);
						formData.append("grant_type", "refresh_token");
						const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
							"base64"
						);
						const headers = { Authorization: "Basic " + authToken };

						axios
							.post(`/api/accounts/oauth/token`, formData, {
								headers,
							})
							.then(({ data }) => {
								const access_token = data.access_token;
								const refresh_token = data.refresh_token;
								localStorage.setItem("access_token", access_token);
								localStorage.setItem("refresh_token", refresh_token);
								axios.defaults.headers.common[
									"Authorization"
								] = `Bearer ${access_token}`;
								originalRequest.headers[
									"Authorization"
								] = `Bearer ${access_token}`;
								processQueue(null, access_token);
								resolve(axios(originalRequest));
							})
							.catch((err) => {
								processQueue(err, null);
								reject(err);
							})
							.finally(() => {
								isRefreshing = false;
							});
					});
				}
				return Promise.reject(error);
			}
		);
	}

	account = {
		login: (data, headers) =>
			this.client.post(`/api/accounts/oauth/token`, data, { headers }),
		refreshToken: (data, headers) =>
			this.client.post(`/api/accounts/oauth/token`, data, { headers }),
		enableTwoFA: () => this.client.get(`/api/accounts/twofa/enable`),
		disableTwoFA: () => this.client.get(`/api/accounts/twofa/disable`),
		checkIsTwoFA: () => this.client.get(`/api/accounts/twofa/check-is`),
		checkCodeTwoFA: (data) =>
			this.client.post(`/api/accounts/twofa/check-code`, data),
		checkInstallTwoFA: (data) =>
			this.client.post(`/api/accounts/twofa/confirm`, data),
		logOut: () => this.client.post(`/api/accounts/current/logout`),
		getData: () => this.client.get(`/api/accounts/account/account`),
		changePassword: (data) =>
			this.client.post(`/api/accounts/account/change-password`, data),
		getWhoami: () => this.client.get(`/api/accounts/account/whoami`),
		sendRegistrationMessage: (data) =>
			this.client.post(
				`/api/accounts/management/mail/send/registration_message`,
				data
			),
	};

	users = {
		getUsers: (params) =>
			this.client.get(`/api/accounts/management/users/users`, { params }),
		getPendingActivationUsers: () =>
			this.client.get(`/api/accounts/management/users/pending`),
		getUserByLogin: (login) =>
			this.client.get(`/api/accounts/management/users/${login}`),
		getAdmins: (params) =>
			this.client.get(`/api/accounts/management/users/admins`, { params }),
		deleteUser: (login) =>
			this.client.delete(`/api/accounts/management/users/${login}`),
		getProfileById: (id) =>
			this.client.get(`/api/userservice/management/profile/${id}`),
		createAdmin: (data) =>
			this.client.post(`/api/accounts/management/users`, data),
		editAdmin: (data) =>
			this.client.put(`/api/accounts/management/users`, data),
		getRoles: () =>
			this.client.get(`/api/accounts/management/users/authorities`),
		addUserVipStatus: (userId) =>
			this.client.post(`/api/userservice/management/vip`, { userId }),
		deleteUserVipStatus: (userId) =>
			this.client.delete(`/api/userservice/management/vip/${userId}`),
		rollbackUserBlockHimself: (userId) =>
			this.client.post(`/api/accounts/management/user/block/rollback`, { userId }),
		// getWalletsById: () =>
		// 	this.client.get(`/api/finance/management/wallets`, { params }),
	};

	statistic = {
		getStatistic: (ticker) =>
			this.client.get(
				`/api/finance/management/statistic/${ticker}/transactions`
			),
		getNetProfit: () =>
			this.client.get(`/api/finance/management/statistic/net_profit`),
	};

	transactions = {
		getList: (params) =>
			this.client.get(`/api/finance/management/transactions`, { params }),
	};

	blockUser = {
		createBlocking: (data) =>
			this.client.post(`/api/userservice/management/blocking`, data),
		getList: () =>
			this.client.get(`/api/userservice/management/blocking?page=0&size=100`),
		getBlocksById: (id) =>
			this.client.get(`/api/userservice/management/blocking/${id}`),
	};

	exchange = {
		getList: () => this.client.get(`/api/exchange/order/list`),
		cryptoExchange: {
			getInternalExchange: (params) =>
				this.client.get("api/exchange/management/order/list", { params }),
			getInternalExchangeId: (id, params) =>
				this.client.get(`api/exchange/management/order/list/${id}`, { params }),
		},
	};

	actionLog = {
		getList: (params) =>
			this.client.get(`/api/userservice/management/log/all`, { params }),
		getById: (params) =>
			this.client.get(`/api/userservice/management/log`, { params }),
	};

	sessionLog = {
		getList: (params) =>
			this.client.get(`/api/userservice/session/logs`, { params }),
	};

	settings = {
		exchange: {
			getList: (params) =>
				this.client.get("/api/exchange/management/market-settings", { params }),
			edit: (data) =>
				this.client.put(`/api/exchange/management/market-settings`, data),
			create: (data) =>
				this.client.post(`/api/exchange/management/market-settings`, data),
			delete: (id) =>
				this.client.delete(`/api/exchange/management/market-settings/${id}`),
			getMaxPrecision: (pair) =>
				this.client.get(
					`/api/exchange/management/market-settings/precision/${pair}`
				),
		},
		withdraw: {
			getList: (params) =>
				this.client.get("/api/finance/management/withdrawal-setting", {
					params,
				}),
			edit: (data) =>
				this.client.put(`/api/finance/management/withdrawal-setting`, data),
			create: (data) =>
				this.client.post(`/api/finance/management/withdrawal-setting`, data),
			delete: (id) =>
				this.client.delete(`/api/finance/management/withdrawal-setting/${id}`),
		},
		deposit: {
			getList: (params) =>
				this.client.get("/api/finance/management/deposit-setting", {
					params,
				}),
			edit: (data) =>
				this.client.put(`/api/finance/management/deposit-setting`, data),
			create: (data) =>
				this.client.post(`/api/finance/management/deposit-setting`, data),
			delete: (id) =>
				this.client.delete(`/api/finance/management/deposit-setting/${id}`),
			getTickerSettings: (ticker) =>
				this.client.get(`/api/finance/management/deposit-settings/${ticker}`),
		},
	};

	finances = {
		internalBalances: {
			getList: (params) =>
				this.client.get("/api/finance/management/node/list", { params }),
		},
		coins: {
			getInfoNodeBlocksEthereum: () =>
				this.client.get(`/api/finance/management/node/ethereum`),
			getInfoNodeBlocksTron: () =>
				this.client.get(`/api/finance/management/node/tron`),
			getNodeDetails: (ticker) =>
				this.client.get(`/api/finance/management/node/info/${ticker}`),
			getWalletsTotal: () =>
				this.client.get(`/api/finance/management/wallets/total`),
		},
		getBalanceBinance: () =>
			this.client.get(`/api/syncservice/balance/binance`),
		getTransfersToMasterWallet: (params) =>
			this.client.get(`/api/finance/management/transfers_to_master_wallet`, {
				params,
			}),
		getBalanceKuna: {
			list: () => this.client.get(`/api/syncservice/balance/kuna`),
			// deposit: params =>
			// 	this.client.get(`/api/exchange/management/deposit/orders`, { params }),
			// withdrawal: params =>
			// 	this.client.get(`/api/exchange/management/withdraw/orders`, { params }),

			deposit: (params) =>
				this.client.get(`/api/finance/management/fiat/deposit`, { params }),
			withdrawal: (params) =>
				this.client.get(`/api/finance/management/fiat/withdraw`, { params }),
		},
		usersBalances: {
			getUsersBalancesByCoin: (params) => {
				const { ticker } = params;
				delete params.ticker;
				// delete params.sort;
				return this.client.get(`/api/finance/management/wallets/${ticker}`, {
					params,
				});
			},
		},
	};

	wallets = {
		getList: (params) =>
			this.client.get(`/api/finance/management/wallets`, { params }),
		getCurrencies: (params) =>
			this.client.get(`/api/finance/currencies`, { params }),
		addNew: (data) => this.client.post(`/api/finance/wallets/new`, data),
		withdraw: (data) => this.client.post(`/api/finance/send`, data),
		getWalletFee: (ticker) =>
			this.client.get(`/api/finance/wallets/fees`, { params: { ticker } }),
		checkInternalAddress: (ticker, address) =>
			this.client.get(`/api/finance/address/validate/${ticker}/${address}`),
	};

	addresses = {
		getList: (ticker) => this.client.get(`/api/finance/addresses/${ticker}`),
		addNew: (ticker, data) =>
			this.client.post(`/api/finance/addresses/${ticker}`, data),
		deleteById: (address_id) =>
			this.client.delete(`/api/finance/address/${address_id}`),
	};

	market = {
		getMarketList: () => this.client.get(`/api/finance/wallets/balance/usd`),
	};
	synchronization = {
		getWithdrawalHistory: (params) =>
			this.client.get("/api/sync/management/withdrawals", {
				params,
			}),
		getSyncStatistics: (params) =>
			this.client.get("/api/sync/management/statistic", {
				params,
			}),
		orderHistory: {
			getBinanceOrderHistory: (params) =>
				this.client.get("/api/sync/management/orders/binance", {
					params,
				}),
			getKunaOrderHistory: (params) =>
				this.client.get("/api/sync/management/orders/kuna", {
					params,
				}),
		},

		syncHistory: {
			getSystemHistory: (params) =>
				this.client.get("/api/sync/management/programs/system", {
					params,
				}),
			getUserHistory: (params) =>
				this.client.get("/api/sync/management/programs/user", {
					params,
				}),
		},
		syncSettings: {
			getList: (params) =>
				this.client.get(
					"/api/syncservice/management/synchronization_settings",
					{
						params,
					}
				),
			getItemById: (id) =>
				this.client.get(
					`/api/syncservice/management/synchronization_settings/${id}`
				),
			create: (data) =>
				this.client.post(
					`/api/syncservice/management/synchronization_settings`,
					data
				),
			edit: (data) =>
				this.client.put(
					"/api/syncservice/management/synchronization_settings",
					data
				),
		},
	};
	dhs = {
		device: {
			getDevices: (params) =>
				this.client.get(`/api/allocation/device/search`, { params }),
			create: (data) => this.client.post(`/api/allocation/device`, data),
			edit: (data) => this.client.put(`/api/allocation/device`, data),
			delete: (deviceId) =>
				this.client.delete(`/api/allocation/device/${deviceId}`),
		},
		getServiceAccountBalances: () => this.client.get(`/api/allocation/balance`),
		getDistributionHistory: (params) =>
			this.client.get(`/api/allocation/history`, { params }),
		getCurrentShares: (params) =>
			this.client.get(`api/allocation/shares`, { params }),
		calculateCurrentShares: () =>
			this.client.post(`/api/allocation/shares/calculate`),
		setSharesDistributionForcefully: (data) =>
			this.client.post(`/api/allocation/shares/set`, data),
		getHistoryUsedPrograms: (params) =>
			this.client.get(`/api/allocation/program`, { params }),
		programStart: (data) =>
			this.client.post(`/api/allocation/program/start`, data),
		getTotalShares: () => this.client.get(`/api/allocation/shares/by_user`),
		getTotalSharesUser: (params) =>
			this.client.get(`/api/allocation/shares/by_user`, { params }),
		getProgramPendingConfirmation: () =>
			this.client.get(`/api/allocation/program/pending`),
		getOwnersHistory: (params) =>
			this.client.get(`/api/allocation/device/owners_history`, { params }),
	};
	kyc = {
		getKycById: (kycId) =>
			this.client.get(`/api/userservice/manage/kyc/${kycId}`),
		getUserKycId: (userId) =>
			this.client.get(`/api/userservice/manage/kyc/history/${userId}`),
		makeDecisionUserKyc: (data) =>
			this.client.patch(`/api/userservice/manage/kyc`, data),
		getKycHistory: (params) =>
			this.client.get(`/api/userservice/manage/kyc`, { params }),
		getDocumentFile: (id) => {
			const headers = { Accept: "*/*" };
			return this.client.get(
				`/api/userservice/manage/kyc/documents/download/${id}`,
				{
					responseType: "arraybuffer",
					headers,
				}
			);
		},
		deleteUserKyc: (userId) =>
			this.client.delete(
				`/api/userservice/management/account/${userId}/kyc/block`
			),
	};

	bankCards = {
		getAllBankCardsList: (params) => this.client.get(`/api/userservice/manage/bank_card`, { params }),
		getBankCardDetailsById: (cardId) =>
			this.client.get(`/api/userservice/manage/bank_card/${cardId}`),
		getPhotoFile: (photoId) => {
			const headers = { Accept: "*/*" };
			return this.client.get(
				`/api/userservice/manage/bank_card/photo/download/${photoId}`,
				{
					responseType: "arraybuffer",
					headers,
				}
			);
		},
		makeDecisionByCard: (data) =>
			this.client.patch(`/api/userservice/manage/bank_card`, data),
	}

	banking = {
		getProviders: () =>
			this.client.get(`/api/syncservice/management/provider/settings/`),
		createNewProvider: (data) =>
			this.client.post(`/api/syncservice/management/provider/settings`, data),
		updateProvider: (data) =>
			this.client.put(`/api/syncservice/management/provider/settings`, data),
		deleteProvider: (provider) =>
			this.client.delete(
				`/api/syncservice/management/provider/settings/${provider}`
			),
		transferFromBankingBalanceToWallet: (data) =>
			this.client.post(
				`/api/syncservice/management/banking/system_balance`,
				data
			),
		getAllBankingBalances: () =>
			this.client.get(`/api/syncservice/management/banking/system_balance`),
		updateBankingBalances: (data) =>
			this.client.patch(
				`/api/syncservice/management/banking/system_balance`,
				data
			),
		getAllBankingSettings: () =>
			this.client.get(`/api/syncservice/management/banking/settings`),
		updateBankingSettings: (data) =>
			this.client.put(`/api/syncservice/management/banking/settings`, data),
		makeDecisionByPendingTransaction: (data) =>
			this.client.post(
				`/api/finance/management/transactions/waiting/change_status`,
				data
			),
		getBankingHistory: (params) =>
			this.client.get(`/api/finance/management/banking/payment`, { params }),
		liquidityProviders: {
			createLiquidityProviderSettings: (data) =>
				this.client.post(`/api/userservice/manage/liquidity_provider/settings`, data),
			updateLiquidityProviderSettings: (data) =>
				this.client.put(`/api/userservice/manage/liquidity_provider/settings`, data),
			deleteLiquidityProviderSettings: (userId) =>
				this.client.delete(`/api/userservice/manage/liquidity_provider/settings/${userId}`),
			getLiquidityProviderSettingsById: (userId) =>
				this.client.get(`/api/userservice/manage/liquidity_provider/settings/${userId}`),
			getAllLiquidityProviderSettingsList: (params) =>
				this.client.get(`/api/userservice/manage/liquidity_provider/settings`, { params }),
			getLiquidityProvidersOffersList: (params) =>
				this.client.get(`/api/finance/manage/liquidity_provider/offer`, {params}),
		}
	};

	capital = {
		funds: {
			createStableInvestmentFund: (data) =>
				this.client.post(`/api/investment/fund/stable`, data),
			createFloatingInvestmentFund: (data) =>
				this.client.post(`/api/investment/fund/floating`, data),
			updateStableInvestmentFund: (data) =>
				this.client.put(`/api/investment/fund/stable`, data),
			updateFloatingInvestmentFund: (data) =>
				this.client.put(`/api/investment/fund/floating`, data),
			searchInvestmentFunds: (params) =>
				this.client.get(`/api/investment/fund/search`, { params }),
			deleteInvestmentFund: (fundId) =>
				this.client.delete(`/api/investment/fund/${fundId}`),
			getTokenPrices: (data) =>
				this.client
					.get(`/api/investment/fund/statistic/{token}/${data.from}/${data.to}
`),
			searchSellInvoices: (params) =>
				this.client.get(`/api/investment/fund/invoice/search`, {
					params,
				}),
			makeDecisionByInvoice: (data) =>
				this.client.post(`/api/investment/fund/invoice`, data),
			searchFundOperationsHistory: (params) =>
				this.client.get(`/api/investment/fund/operations/history/search`, {
					params,
				}),
			searchUserFundBalances: (params) =>
				this.client.get(`/api/investment/fund/balance/search`, {
					params,
				}),
			getSystemBalancesFunds: () =>
				this.client.get(`/api/investment/fund/system_wallet`),
			sendFromSystemBalanceToWalletByAddress: (data) =>
				this.client.post(`/api/investment/fund/system_wallet`, data),
			searchForTransfersFromSystemBalanceToWallet: (params) =>
				this.client.get(`/api/investment/fund/system_wallet/transfers`, {
					params,
				}),
			distributeDividends: (data) =>
				this.client.post(
					`/api/investment/fund/dividends/program/distribute`,
					data
				),
			searchDividendsDistributionPrograms: (params) =>
				this.client.get(`/api/investment/fund/dividends/program/search`, {
					params,
				}),
			searchDividends: (params) =>
				this.client.get(`/api/investment/fund/dividends/search`, {
					params,
				}),
			getAvailableVipFunds: () => this.client.get(`/api/investment/fund/private`),
			getEstimateDividendsInfo: (fundId) => this.client.get(`api/investment/fund/dividends/program/estimate_dividends/${fundId}`),
			createFundPayoutBalance: (data) => this.client.post(`/api/finance/management/fund/payout/balance`, data),
			getFundPayoutBalanceById: (balanceId) => this.client.get(`/api/finance/management/fund/payout/balance/${balanceId}`),
			getAllFundPayoutBalances: (params) => this.client.get(`/api/finance/management/fund/payout/balance`, { params }),
			transferFromFundPayoutBalance: (data) => this.client.post(`/api/finance/management/fund/payout/balance/transfer`, data),
			getFundPayoutBalanceTransferHistory: (params) => this.client.get(`/api/finance/management/fund/payout/balance/transfer`, { params })
		},
		privateUserFunds: {
			getAllEnabledUserFunds: (userId) => this.client.get(`/api/investment/management/fund/user_settings/${userId}`),
			enablePrivateFundForUser: ({ userId, fundId }) => this.client.post(`/api/investment/management/fund/user_settings`, {
				userId,
				fundId
			}),
			deletePrivateFundForUser: (userId) => this.client.delete(`/api/investment/management/fund/user_settings/${userId}`),
		},
		indexes: {
			createInvestmentIndex: (data) =>
				this.client.post(`/api/investment/index`, data),
			updateInvestmentIndex: (data) =>
				this.client.put(`/api/investment/index`, data),
			deleteInvestmentIndex: (indexId) =>
				this.client.delete(`/api/investment/index/${indexId}`),
			searchInvestmentIndexes: (params) =>
				this.client.get(`/api/investment/index/search`, { params }),
			searchUserIndexBalances: (params) =>
				this.client.get(`/api/investment/index/balance/search`, {
					params,
				}),
			searchIndexOperationsHistory: (params) =>
				this.client.get(`/api/investment/index/operations/history/search`, {
					params,
				}),
			searchUsersIndexInvoices: (params) =>
				this.client.get(`/api/investment/index/invoice/search`, {
					params,
				}),
			makeDecisionByInvoiceIndex: (data) =>
				this.client.post(`/api/investment/index/invoice`, data),
			searchSendToBinancePrograms: (params) =>
				this.client.get(`/api/investment/index/program/search`, {
					params,
				}),
			getSystemBalancesIndexes: () =>
				this.client.get(`/api/investment/index/system_wallet`),
			transferFromSystemBalanceToWallet: (data) =>
				this.client.post(`/api/investment/index/system_wallet`, data),
			searchForTransfersFromSystemBalanceIndexToWallet: (params) =>
				this.client.get(`/api/investment/index/system_wallet/transfers`, {
					params,
				}),
			getIndexAllAssetsList: (params) =>
				this.client.get(`/api/investment/index/additional_asset`, {
					params
				}),
			createNewIndexAsset: (data) =>
				this.client.post(`/api/investment/index/additional_asset`, data),
			updateIndexAssetInfo: (data) =>
				this.client.put(`/api/investment/index/additional_asset`, data),
			getIndexAssetByAssetId: (assetId) =>
				this.client.get(`/api/investment/index/additional_asset/${assetId}`),
			getIndexAssetsListByIndexId: (indexId) =>
				this.client.get(`/api/investment/index/${indexId}/additional_asset`),
			deleteIndexAsset: (assetId) =>
				this.client.delete(`/api/investment/index/additional_asset/${assetId}`),
			manuallyRefreshIndexAssetsRates: (params) =>
				this.client.get(`/api/investment/index/additional_asset/price/refresh`, {
					params
				}),
		},
	};
	referrals = {
		searchReferralBalances: (params) =>
			this.client.get(`/api/referral/balance/search`, {
				params,
			}),
		searchClaimHistory: (params) =>
			this.client.get(`/api/referral/claim/history/search`, {
				params,
			}),
		searchReferralReward: (params) =>
			this.client.get(`/api/referral/rewards/search`, {
				params,
			}),
		getDefaultReferralSettings: () =>
			this.client.get(`/api/referral/settings/default`),
		updateDefaultReferralSettings: (data) =>
			this.client.put(`/api/referral/settings/default`, data),
		getVipReferralSettings: (params) =>
			this.client.get(`/api/referral/settings/vip`, {
				params,
			}),
		getVipReferralSettingsByUserId: (userId) =>
			this.client.get(`/api/referral/settings/vip/${userId}`),
		updateVipReferralSettings: (data) =>
			this.client.put(`/api/referral/settings/vip`, data),
		deleteVipReferralSettings: (settingsId) =>
			this.client.delete(`/api/referral/settings/vip/${settingsId}`),
		createVipReferralSettings: (data) =>
			this.client.post(`/api/referral/settings/vip`, data),
		getReferrals: (params) =>
			this.client.get(`/api/referral/users/search`, {
				params,
			}),
		changeParent: (data) =>
			this.client.put(`/api/referral/users/change-parent`, data),
		// USER PROFILE VIP REFERAL SETTINGS
		getVipUserProfileReferralDefaultSettings: (userId) =>
			this.client.get(`/api/referral/settings/vip/${userId}`),
		getVipUserProfileReferralFundSettings: (userId) =>
			this.client.get(`/api/referral/settings/vip/${userId}/fund`),
		getVipUserProfileReferralExchangeSettings: (userId) =>
			this.client.get(`/api/referral/settings/vip/${userId}/exchange`),
		getVipUserProfileReferralAllSettings: (userId) =>
			this.client.get(`/api/referral/settings/vip/${userId}/all`),
		createVipUserProfileReferralDefaultSettings: (data) =>
			this.client.post(`/api/referral/settings/vip`, data),
		createVipUserProfileReferralExchangeSettings: (data) =>
			this.client.post(`/api/referral/settings/vip/exchange`, data),
		createVipUserProfileReferralFundSettings: (data) =>
			this.client.post(`/api/referral/settings/vip/fund`, data),
		updateVipUserProfileReferralDefaultSettings: (data) =>
			this.client.put(`/api/referral/settings/vip`, data),
		updateVipUserProfileReferralExchangeSettings: (data) =>
			this.client.put(`/api/referral/settings/vip/exchange`, data),
		updateVipUserProfileReferralFundSettings: (data) =>
			this.client.put(`/api/referral/settings/vip/fund`, data),
		deleteVipUserProfileReferralDefaultSettings: (settingsId) =>
			this.client.delete(`/api/referral/settings/vip/${settingsId}`,),
		deleteVipUserProfileReferralExchangeSettings: (settingsId) =>
			this.client.delete(`/api/referral/settings/vip/exchange/${settingsId}`,),
		deleteVipUserProfileReferralFundSettings: (settingsId) =>
			this.client.delete(`/api/referral/settings/vip/fund/${settingsId}`,),
	};
	companies = {
		getAllCompanies: (params) =>
			this.client.get(`/api/finance/management/company`, {
				params: {
					sort: 'id,asc',
					...params
				},
			}),
		createCompany: (data) =>
			this.client.post(`/api/finance/management/company`, data),
		updateCompany: (data) =>
			this.client.put(`/api/finance/management/company`, data),
		deleteCompany: (companyId) =>
			this.client.delete(`/api/finance/management/company/${companyId}`),
		activateCompany: (companyId) =>
			this.client.patch(`/api/finance/management/company/activate/${companyId}`),
		deactivateCompany: (companyId) =>
			this.client.patch(`/api/finance/management/company/deactivate/${companyId}`),
		getCompanyById: (companyId) =>
			this.client.get(`/api/finance/management/company/${companyId}`),
		getCompaniesByName: (searchName) =>
			this.client.get(`/api/finance/management/company/by_name/${searchName}`),
		getCompanyBalances: (companyId) =>
			this.client.get(`/api/finance/management/company/balance/by_company/${companyId}`),
		getCompanyBalanceById: (balanceId) =>
			this.client.get(`/api/finance/management/company/balance/${balanceId}`),
		createCompanyBalance: (data) =>
			this.client.post(`/api/finance/management/company/balance`, data),
		deleteCompanyBalance: (balanceId) =>
			this.client.delete(`/api/finance/management/company/balance/${balanceId}`),
		getCompanyBalanceUsers: (balanceId) =>
			this.client.get(`/api/finance/management/company/balance/user/by_balance/${balanceId}`),
		getCompanyBalanceUserById: (userId) =>
			this.client.get(`/api/finance/management/company/balance/user/${userId}`),
		createCompanyBalanceUser: (data) =>
			this.client.post(`/api/finance/management/company/balance/user`, data),
		deleteCompanyBalanceUser: (userId) =>
			this.client.delete(`/api/finance/management/company/balance/user/${userId}`),
		getCompanyBalanceTransferHistory: (params) =>
			this.client.get(`/api/finance//management/company/balance/transfer/search`, {
				params,
			}),
		createCompanyBalanceSecuritySettings: (data) =>
			this.client.post(`/api/finance/management/company/balance/settings/security`, data),
		updateCompanyBalanceSecuritySettings: (data) =>
			this.client.put(`/api/finance/management/company/balance/settings/security`, data),
		deleteCompanyBalanceSecuritySettings: (settingsId) =>
			this.client.delete(`/api/finance/management/company/balance/settings/security/${settingsId}`),
		getCompanyBalanceSecuritySettingsBySettingsId: (settingsId) =>
			this.client.get(`/api/finance/management/company/balance/settings/security/${settingsId}`),
		getCompanyBalanceSecuritySettingsList: (balanceId) =>
			this.client.get(`/api/finance/management/company/balance/${balanceId}/settings/security`),
		createCompanyBalanceUserForSecuritySettings: (data) =>
			this.client.post(`/api/finance/management/company/balance/settings/security/user`, data),
		getCompanyBalanceUsersOfSecuritySettings: (settingsId) =>
			this.client.get(`/api/finance/management/company/balance/settings/security/${settingsId}/user`),
		deleteCompanyBalanceUserOfSecuritySettings: (userId) =>
			this.client.delete(`/api/finance/management/company/balance/settings/security/user/${userId}`),
		getCompanyBalanceTransferInvoiceById: (invoiceId) =>
			this.client.get(`/api/finance/management/company/balance/invoice/${invoiceId}`),
		getAllCompanyBalanceTransferInvoicesList: (params) =>
			this.client.get(`/api/finance/management/company/balance/invoice/search`, params)
	}
}

const api = new Api();

export default api;
